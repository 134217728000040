import request from '../utils/request'

const VERSION = process.env.VUE_APP_VERSION

//视频评分
export function assessScore(data) {
  return request({
    url: VERSION + '/video/score',
    method: 'post',
    params: data
  })
}
//视频动态资源
export function getAssessnum(id) {
  return request({
    url: VERSION + '/video/' + id,
    method: 'get'
  })
}
//相关视频
export function getRelated(id) {
  return request({
    url: VERSION + '/video/related/' + id,
    method: 'get'
  })
}
export function getQrcode(id) {
  return request({
    url: VERSION + '/qrcode/getInfo/' + id,
    method: 'get'
  })
}
