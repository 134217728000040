<template>
  <div class="home">
    <div class="course-video-box">
      <div class="content-header">
        <div class="header-info">
          <el-row>
            <el-col :span="3">
              <div class="user-pic">
                <!-- <img :src="avatar" alt="" /> -->
                <el-image
                  style="width: 100%; height: 100%"
                  :src="avatar"
                  fit="cover"
                />
              </div>
              <p class="placeholder"></p>
            </el-col>
            <el-col :span="18" class="user-info">
              <h2>{{ real_name }}</h2>
              <p>
                <span class="icon-location2"></span>{{ licence_name }} ·
                {{ jiaxiao_name }}
              </p>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="course-title">
        <div class="title-info">
          <h2>当前播放：{{ playName }}</h2>
          <ul>
            <li
              v-for="(item, i) in subjectdata"
              :key="item.subject_id"
              @click="getsubjectid(item.subject_id, i)"
            >
              <a v-bind:class="item.classType ? 'active' : ''">
                {{ item.subject_name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <el-row class="course-directory">
        <el-col :span="16" class="course-directory-list">
          <div class="course-video">
            <div id="test"></div>
          </div>
          <div class="evaluate-box">
            <el-row>
              <el-col :span="2">课程评价:</el-col>
              <el-col :span="5"
                ><el-rate
                  v-model="value"
                  :texts="this.texts"
                  show-text
                ></el-rate
              ></el-col>
              <el-col :span="8"
                ><span>{{ assessNum }}</span
                >人已评价</el-col
              >
            </el-row>
          </div>
          <el-input
            v-model="assessinput"
            placeholder="请输入评价内容"
            style="margin-top:15px;width:88%"
          ></el-input>
          <el-button
            type="primary"
            @click="assessScore()"
            style="float: right;margin-top: 15px;"
            >提交评价</el-button
          >
          <div class="directory-list-item">
            <el-tree
              ref="my-tree"
              :data="video_data"
              :props="defaultProps"
              node-key="course_name"
              :default-expanded-keys="[this.playName]"
              :current-node-key="playName"
              highlight-current
              accordion
              @node-click="handleNodeClick"
            >
              <p class="custom-tree-node" slot-scope="{ node, data }">
                <span
                  v-if="node.level == 1"
                  style="color:#000;font-weight: bold;"
                >
                  {{ node.label }}
                </span>
                <span v-if="node.level == 2" style="color:#000;">
                  {{ node.label }}
                </span>
                <span v-if="node.level == 3">{{ node.label }}</span>
                <span v-if="data['cc_id'] != undefined" style="float:right">
                  <span
                    v-if="data['is_finish'] == 0"
                    class="icon-circle-o"
                  ></span>
                  <span
                    v-if="data['is_finish'] == 1"
                    class="icon-circle1"
                  ></span>
                  <span
                    v-if="data['is_finish'] == 2"
                    class="icon-contrast"
                  ></span>
                  <span
                    v-if="data['is_finish'] == 3"
                    class="icon-dot-circle-o"
                  ></span>
                </span>
              </p>
            </el-tree>
          </div>
          <!-- <div
            v-for="item in video_data"
            :key="item.chapter_id"
            class="directory-list-item"
          >
            <h2>{{ item.name }}</h2>
            <dl v-for="item2 in item.children" :key="item2.chapter_id">
              <dt>{{ item2.course_name }}</dt>
              <dd
                v-for="item3 in item2.children"
                :key="item3.chapter_id"
                @click="
                  play_video(
                    item3.cc_id,
                    item3.is_finish,
                    item3.course_name,
                    item3.video_id,
                    item3.duration
                  )
                "
              >
                <span class="icon-play2"></span>
                <a>{{ item3.course_name }}</a>
                <span v-if="item3.is_finish == 0" class="icon-circle-o"></span>
                <span v-if="item3.is_finish == 1" class="icon-circle1"></span>
                <span v-if="item3.is_finish == 2" class="icon-contrast"></span>
                <span
                  v-if="item3.is_finish == 3"
                  class="icon-dot-circle-o"
                ></span>
              </dd>
            </dl>
          </div> -->
        </el-col>
        <el-col :span="5" :offset="1" class="learn-progress">
          <div class="progress-info">
            <h3>
              <p>已学</p>
              <p>
                已学<span>{{ parseInt(this.daily_duration / 60) }}</span
                >分钟
              </p>
            </h3>
            <el-progress :percentage="progress"></el-progress>
            <p class="at-present">上次学到: {{ playName }}</p>
            <div class="continue-learning">
              <el-button type="primary" round @click="keepOn(butname)"
                >{{ butname }}
              </el-button>
            </div>
            <div class="warm-prompt">
              <h2>温馨提示：</h2>
              <p style="white-space:pre-wrap">{{ rule.rule_content }}</p>
              <p>
                注：结束学习，请务必点击【签退】并进行人脸验证，否则影响学时上传
              </p>
              <p>注：为了保证学时能够即时上传，请使用手机APP观看视频。</p>
              <p>注：用户正常签退时，会自动刷新网页更新最新学时</p>
            </div>
          </div>
          <div class="child-ad">
            <img alt="" src="../assets/images/banner-x.jpg" />
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      :visible.sync="dialogTableVisible"
      center
      class="collect-pictures"
      title="采集照片"
      width="40%"
      :before-close="closeVideocollection"
    >
      <div class="pic-box">
        <video
          v-show="cameracollection"
          ref="videocollection"
          id="videocollection"
          autoplay="autoplay"
        ></video>
        <canvas
          v-show="imgcollection"
          id="canvascollection"
          width="168px"
          height="235px"
        ></canvas>
        <img
          v-show="imgscollection"
          id="imgTagcollection"
          src=""
          alt="imgTag"
          :videoId="currvideo.video_id"
          style="width:168px;height:235px"
        />
      </div>
      <div class="photo-info">
        <p>提示：</p>
        <p>1、请保证光线充足，照片清晰；</p>
        <p>
          2、此采集的照片将作为后期学习人脸验证的对照照片，并且采集后不可修改。
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogTableVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="takephotos">采集照片</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      title="人脸识别"
      width="60%"
      :before-close="closeVideo"
    >
      <el-dialog
        :visible.sync="innerVisible"
        append-to-body
        center
        title="识别结果"
        width="30%"
      >
        <div class="results-suggest">
          <span :class="playstatus"></span>
          <p class="">{{ playtext }}</p>
          <span slot="footer" class="dialog-footer">
            <el-button @click="innerVisible = false">取 消</el-button>
            <el-button type="primary" @click="playresult(type)"
              >确 定</el-button
            >
          </span>
        </div>
      </el-dialog>
      <div class="msg-content">
        <el-row>
          <el-col :span="7">
            <div class="default-photo">
              <span v-if="imgpath == ''" class="icon-user11"></span>
              <img
                v-if="imgpath !== ''"
                :src="imgpath"
                alt=""
                style="width:129px;height:151px"
              />
            </div>
          </el-col>
          <el-col :span="5">
            <div class="bocom-icon">
              <span class="icon-two-way-arrow"></span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="pictures-box">
              <!--              <span class="icon-photo"></span>-->
              <video
                v-show="camera"
                ref="video"
                id="video"
                autoplay="autoplay"
              ></video>
              <canvas
                v-show="img"
                id="canvas"
                width="350px"
                height="350px"
              ></canvas>
              <img
                v-show="imgs"
                id="imgTag"
                src=""
                alt="imgTag"
                :videoId="currvideo.video_id"
              />
            </div>
            <el-button
              style="margin-top: 215px;"
              class="picture-btn"
              plain
              round
              size="medium"
              type="primary"
              @click="startdis"
              >识别
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div class="msg-footer">
        <el-divider content-position="left">温馨提示</el-divider>
        <div>
          <p>1、请确保是本人操作；</p>
          <p>2、验证成功，可以继续学习视频课程；</p>
          <p>3、三次验证失败，可重新进入学习获得验证机会。</p>
        </div>
      </div>
    </el-dialog>
    <!--活体检测-->
    <el-dialog
      :visible.sync="dialogBiopsy"
      center
      class="collect-pictures"
      title="活体检测"
      width="40%"
      :before-close="closeBiopsy"
      :close-on-click-modal="false"
    >
      <div class="pic-box">
        <video
          v-show="videoBiopsy"
          playsinline
          src=""
          class="myCamera"
          id="myCamera"
          ref="myCamera"
        ></video>
        <canvas
          v-show="imgBiopsy"
          id="canvasBiopsy"
          width="168px"
          height="235px"
        ></canvas>
        <img
          v-show="imgsBiopsy"
          id="imgTagBiopsy"
          src=""
          alt="imgTag"
          :videoId="currvideo.video_id"
          style="width:168px;height:235px"
        />
      </div>
      <div
        class="photo-info"
        style="text-align:center;letter-spacing:8px;font-size:24px;"
      >
        {{ this.strBiopsy }}
        <!-- <p>提示：</p>
        <p>1、请保证光线充足，照片清晰；</p>
        <p>2、请用普通话朗读数字</p> -->
      </div>
      <el-progress :percentage="progressPercent"></el-progress>
      <span slot="footer" class="dialog-footer">
        <div class="starting" @click="starting" v-if="isStart">
          <el-button type="primary">开始检测</el-button>
        </div>
        <div class="stopping" v-if="isMiddle">
          <el-button type="primary">检测中</el-button>
        </div>
        <div class="stopping" v-if="isEnd" @click="stopping">
          <el-button type="primary">检测成功</el-button>
        </div>
        <div class="stopping" v-if="isEnds" @click="stopping">
          <el-button type="primary">检测失败</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getIsPay,
  getLicenceName,
  getResult,
  getSubjectId,
  getTerm,
  getUuid,
  removeResult,
  removeSubjectId,
  setSubjectId,
  setUuid
} from '../utils/auth'
import { courserule, getCourses, getSubject, studydaily } from '../api/courses'
import { getDaily, gethandler, getprogress } from '../api/daily'
import { collection } from '../api/user'
import { assessScore, getAssessnum } from '../api/video'
import RecordRTC from 'recordrtc'

export default {
  name: 'home',
  data() {
    return {
      finish_duration: 0,
      subject_duration: 0,
      value: null,
      timerTime: 0,
      type: 0,
      playstatus: '',
      playtext: '',
      progress: 0,
      subjectdata: [],
      term: 0,
      butname: '继续学习',
      avatar: '',
      real_name: '',
      licence_title: '',
      jiaxiao_name: '',
      licence_name: '',
      subject_id: 0,
      video_data: [],
      old_video_data: [],
      rule: [],
      is_pay: 0,
      duration_limit: '',
      is_finish: 0,
      total_study_time: 0,
      period: '',
      progressbar_enable: '',
      playName: '',
      assessNum: 0,
      videoScore: 0,
      texts: ['非常不满意', '不满意', '一般', '满意', '非常满意'],
      cc_id: '',
      video_id: '',
      dialogTableVisible: false,
      centerDialogVisible: false,
      innerVisible: false,
      imgpath: '',
      camera: true,
      img: false,
      imgs: false,
      cameracollection: true,
      imgcollection: false,
      imgscollection: false,
      isFrist: false,
      times: 1,
      step: '',
      verify_type: 0,
      position: '',
      uuid: '',
      point: 0,
      duration: 0,
      starttime: 0,
      curTime: 0,
      videolist: [],
      idx: 0,
      currvideo: { video_id: 0, cc_id: '' },
      isFlag: 0,
      limitduration: 0,
      nowTime: '',
      sTime: '',
      eTime: '',
      ciRecoderStime: '',
      ciRecoderEtime: '',
      total_duration: '',
      collectionImageData: [],
      distinguishImageData: [],
      biopsyImageData: [],
      timer_duration: 0,
      time1: 0,
      timer: 0,
      indexData: [],
      daily_duration: 0,
      logDuration: 0,
      hour_limit: 0,
      subject_one: 0,
      subjectHour: 0,
      is_pause: true,
      typeImage: '',
      cata_id: 0,
      historyPoint: 0,
      subject1_period: 0,
      assessinput: '',
      dialogBiopsy: false,
      strBiopsy: '',
      isStart: true,
      isMiddle: false,
      isEnd: false,
      isEnds: false,
      progressPercent: 0,
      videoBiopsy: true,
      imgBiopsy: false,
      imgsBiopsy: false,
      defaultProps: {
        children: 'children',
        label: 'course_name'
      },
      remain_duration: 0
    }
  },
  created() {
    this.indexData = this.$route.query
    if (this.indexData.licence_id !== undefined) {
      this.licence_id = this.indexData.licence_id
    } else {
      this.licence_id = JSON.parse(getResult()).licence_id
    }

    this.licence_name = getLicenceName()
    //this.licence_id = JSON.parse(getResult()).licence_id
    //this.subject_id = getSubjectId()
    this.term = getTerm()
    getSubject({ licence_id: this.licence_id }).then(res => {
      if (res.code === 0) {
        this.subjectdata = res.result.subject_list
      }
      for (let s = 0; s < this.subjectdata.length; s++) {
        this.subject_one = this.subjectdata[0].subject_id
        if (getSubjectId() !== undefined) {
          if (getSubjectId() == this.subjectdata[s].subject_id) {
            this.subject_id = getSubjectId()
            this.subjectdata[s].classType = true
          }
        } else {
          if (s === 0) {
            this.subject_id = this.subjectdata[0].subject_id
            this.subjectdata[0].classType = true
          } else {
            this.subjectdata[s].classType = false
          }
        }
      }
      this.getProgress()
      this.getInfo()
      this.getCourses()
      window.ccH5PlayerJsLoaded = true
      this.setPlayerCallBack()
      this.hoursLimit()
      this.subjectOnehours()
    })
    this.getrule()
  },
  methods: {
    handleNodeClick(params) {
      //cc_id, is_finish, course_name, video_id, duration
      // console.log(params);

      if (params.hasOwnProperty('children') === false) {
        let cc_id = params['cc_id']
        let is_finish = params['is_finish']
        let course_name = params['course_name']
        let video_id = params['video_id']
        let duration = params['duration']
        this.currvideo.video_id = video_id
        this.currvideo.duration = duration
        if (this.rule.order === 0) {
          for (let i = 0; i < this.video_data.length; i++) {
            for (let j = 0; j < this.video_data[i].children.length; j++) {
              for (
                let k = 0;
                k < this.video_data[i].children[j].children.length;
                k++
              ) {
                const data = this.video_data[i].children[j].children[k]
                if (data) {
                  if (cc_id === data.cc_id) {
                    this.period = data.period
                    this.duration = data.duration
                    // this.total_duration = data.
                    data.is_finish = 3
                  } else {
                    data.is_finish = this.old_video_data[i].children[
                      j
                    ].children[k].is_finish
                  }
                }
              }
            }
          }
          this.video_id = video_id
          this.cc_id = cc_id
          this.playName = course_name
          //树状图默认选中
          this.$nextTick(() => {
            this.$refs['my-tree'].setCurrentKey(this.playName)
          })
          this.getAssessnum(video_id)
          this.createPlayer(this.cc_id, this.playName, this.video_id)
        } else {
          this.$message({
            message: '检测到规则为顺序播放，点击无效！',
            type: 'warning'
          })
        }
      }
    },
    //获取已学分钟
    getProgress() {
      let params = {
        licence_id: JSON.parse(getResult()).licence_id,
        term: getTerm()
      }
      getprogress(params).then(res => {
        if (res.code === 0) {
          //已学学时
          for (
            let i = 0;
            i < res.result.duration.daily_study_duration.length;
            i++
          ) {
            if (
              this.subject_id ==
              res.result.duration.daily_study_duration[i].subject
            ) {
              this.daily_duration =
                res.result.duration.daily_study_duration[i].duration
            }
          }
          //进度条
          for (let i = 0; i < res.result.duration.finish_duration.length; i++) {
            if (
              this.subject_id == res.result.duration.finish_duration[i].subject
            ) {
              this.finish_duration =
                res.result.duration.finish_duration[i].duration
            }
            if (
              this.subject_id == res.result.duration.subject_duration[i].subject
            ) {
              this.subject_duration =
                res.result.duration.subject_duration[i].duration
            }
          }
          if (
            parseInt((this.finish_duration / this.subject_duration) * 100) >=
            100
          ) {
            this.progress = 100
          } else {
            this.progress = parseInt(
              (this.finish_duration / this.subject_duration) * 100
            )
          }
          if (isNaN(this.progress)) {
            this.progress = 0
          }
        }
      })
    },
    //获取规则
    getrule() {
      let params = {
        licence_id: this.licence_id,
        term: this.term
      }
      courserule(params).then(res => {
        if (res.code === 0) {
          this.rule = res.result
          // this.rule = {
          //   buybtn: 1,
          //   check_interval: 0,
          //   checked: 0,
          //   course_front_ad: 0,
          //   course_study_ad: 0,
          //   drag: 0,
          //   duration_limit: 10800,
          //   exam_button_word: 0,
          //   exam_duration: 0,
          //   exam_isshow: 0,
          //   exam_limit: 0,
          //   exam_pass_template: 0,
          //   exam_submit_content: '',
          //   index_pay_window: 0,
          //   is_collection: 1,
          //   is_pay: 1,
          //   jump: 0,
          //   log_duration_limit: 0,
          //   logout: 0,
          //   order: 0,
          //   photo_num: 0,
          //   playing: 0,
          //   push_period_isshow: 1,
          //   rule_content:
          //     '1、请按照顺序学习课程\n2、每天学习课程时间不得超过180分钟\n3、按照要求进行人脸拍照',
          //   rule_name: '2-江西九江初学-购买',
          //   score_isshow: 0,
          //   start: 0,
          //   stop: 0,
          //   study_time_zone: [],
          //   subject1_duration_limit: 162000,
          //   tiku_referee: 0,
          //   unit: 2700
          // }
          this.duration_limit = this.rule.duration_limit
          this.progressbar_enable = this.rule.drag
          if (this.rule.study_time_zone.length > 0) {
            let myDate = new Date()
            let year = myDate.getFullYear()
            let month = myDate.getMonth() + 1 //获取的数值是0-11，用来表示月份要+1
            let day = myDate.getDate()
            let sTime =
              `${year}-${month}-${day}` + ' ' + this.rule.study_time_zone[0]
            let eTime =
              `${year}-${month}-${day}` + ' ' + this.rule.study_time_zone[1]
            this.nowTime = Date.parse(new Date()) / 1000
            this.sTime = Date.parse(new Date(sTime)) / 1000
            this.eTime = Date.parse(new Date(eTime)) / 1000
          } else {
            this.nowTime = 0
            this.sTime = 0
            this.eTime = 0
          }
        } else if (res.code === 1) {
          this.rule = res.result
        } else if (res.code === -1) {
          this.$alert('登录过期，请登录后再访问', '登录提示', {
            confirmButtonText: '重新登录',
            callback: action => {
              removeResult()
              location.href = '#/login'
            }
          })
        }
      })
    },
    //科目一已学总学时
    subjectOnehours() {
      getDaily({
        subject_id: this.subject_one,
        term: getTerm()
      }).then(res => {
        this.subjectHour = 0
        if (res.code === 0) {
          for (let i = 0; i < res.result.length; i++) {
            this.subjectHour += parseInt(res.result[i].duration)
          }
        }
      })
    },
    //学时限制
    hoursLimit() {
      getDaily({
        subject_id: this.subject_id,
        term: getTerm()
      }).then(res => {
        if (res.code === 0) {
          //当天0点
          this.day_starttime =
            new Date(new Date().toLocaleDateString()).getTime() / 1000
          //当天23.59.59
          this.day_endtime = this.day_starttime + 86399
          for (let i = 0; i < res.result.length; i++) {
            if (
              Date.parse(res.result[i].starttime) / 1000 >=
                this.day_starttime &&
              Date.parse(res.result[i].endtime) / 1000 <= this.day_endtime
            ) {
              this.hour_limit += parseInt(res.result[i].duration)
            }
          }
        }
      })
    },
    //用户信息
    getInfo() {
      this.avatar = JSON.parse(getResult()).avatar
      this.real_name = JSON.parse(getResult()).real_name
      this.licence_title = JSON.parse(getResult()).licence_title
      this.jiaxiao_name = JSON.parse(getResult()).jiaxiao_name
      this.imgpath = JSON.parse(getResult()).checkphoto
    },
    //获取科目
    getsubjectid(subject_id, i) {
      if (
        this.rule.subject1_duration_limit !== 0 &&
        subject_id !== this.subject_one
      ) {
        if (this.subjectHour >= this.rule.subject1_duration_limit) {
          for (let s = 0; s < this.subjectdata.length; s++) {
            this.subjectdata[s].classType = false
          }
          this.$set(
            this.subjectdata[i],
            'classType',
            this.subjectdata[i].classType === false
          )
          this.$forceUpdate()
          this.subject_id = subject_id
          this.getCourses()

          removeSubjectId()
          setSubjectId(subject_id)
          this.getProgress()
          clearInterval(this.time1) //清空计时器
          this.times = 1 //开始学习
          this.step = 'start'
          this.position = 'daily-start'
          this.image = ''
          this.butname = '继续学习'
        } else {
          this.$message({
            message: '科目一学时不够，禁止跳转科目',
            type: 'warning'
          })
        }
      } else {
        for (let s = 0; s < this.subjectdata.length; s++) {
          this.subjectdata[s].classType = false
        }
        this.$set(
          this.subjectdata[i],
          'classType',
          this.subjectdata[i].classType === false
        )
        this.$forceUpdate()
        this.subject_id = subject_id
        this.getCourses()
        removeSubjectId()
        setSubjectId(subject_id)
        this.getProgress()
        clearInterval(this.time1) //清空计时器
        this.times = 1 //开始学习
        this.step = 'start'
        this.position = 'daily-start'
        this.image = ''
        this.butname = '继续学习'
      }
      // if (this.rule.subject1_duration_limit !== 0 && subject_id !== 1) {
      //   if (this.total_study_time >= this.rule.subject1_duration_limit) {
      //     for (let s = 0; s < this.subjectdata.length; s++) {
      //       this.subjectdata[s].classType = false
      //     }
      //     this.$set(
      //       this.subjectdata[i],
      //       'classType',
      //       this.subjectdata[i].classType === false
      //     )
      //     this.$forceUpdate()
      //     this.subject_id = subject_id
      //     this.getCourses()
      //   } else {
      //     this.$message({
      //       message: '科目一学时不够，禁止跳转科目',
      //       type: 'warning'
      //     })
      //   }
      // } else {
      //   for (let s = 0; s < this.subjectdata.length; s++) {
      //     this.subjectdata[s].classType = false
      //   }
      //   this.$set(
      //     this.subjectdata[i],
      //     'classType',
      //     this.subjectdata[i].classType === false
      //   )
      //   this.$forceUpdate()
      //   this.subject_id = subject_id
      //   this.getCourses()
      //   removeSubjectId()
      //   setSubjectId(subject_id)
      //   this.getProgress()
      // }
    },
    //获取课程
    getCourses() {
      let params = {
        term: this.term,
        licence_id: this.licence_id,
        subject_id: this.subject_id
      }
      if (getIsPay() >= 2) {
        params['province_id'] = JSON.parse(getResult()).province_id
        params['city_id'] = JSON.parse(getResult()).city_id
        params['jiaxiao_id'] = JSON.parse(getResult()).jiaxiao_id
        params['appid'] = JSON.parse(getResult()).user_source
      } else {
        params['province_id'] = 0
        params['city_id'] = 0
        params['jiaxiao_id'] = 0
        params['appid'] = '1' //1
      }
      getCourses(params).then(res => {
        if (res.result.hasOwnProperty('course') === true) {
          this.cata_id = res.result.cata_id
          this.total_duration = res.result.period
          this.period = res.result.course.period
          //科目一总学时
          this.subject1_period = res.result.course.period
          //this.progress = res.result.course.progress
          this.video_data = res.result.course.course_list
          this.old_video_data = JSON.parse(JSON.stringify(this.video_data))
          if (res.result.course.last_video !== null) {
            this.historyPoint = res.result.course.last_video.point
            this.playName = res.result.course.last_video.course_name
            //树状图默认选中
            this.$nextTick(() => {
              this.$refs['my-tree'].setCurrentKey(this.playName)
            })
            this.getAssessnum(res.result.course.last_video.video_id)
            for (let i = 0; i < this.video_data.length; i++) {
              for (let j = 0; j < this.video_data[i].children.length; j++) {
                for (
                  let k = 0;
                  k < this.video_data[i].children[j].children.length;
                  k++
                ) {
                  const data = this.video_data[i].children[j].children[k]
                  if (data) {
                    if (
                      res.result.course.last_video.cc_id === data.cc_id &&
                      res.result.course.last_video.course_name ===
                        data.course_name
                    ) {
                      this.period = data.period
                      this.duration = data.duration
                      // this.total_duration = data.
                      data.is_finish = 3
                      this.video_id = data.video_id
                    }
                  }
                }
              }
            }
            let lastVid = ''
            if (res.result.course.last_video !== null) {
              lastVid = res.result.course.last_video.cc_id
            }
            this.getVideolist(res.result.course.course_list, lastVid)
            this.playVideoList()
          }
        }
      })
    },
    //评论人数
    getAssessnum(video_id) {
      this.videoScore = video_id
      getAssessnum(video_id).then(res => {
        if (res.code === 0) {
          this.assessNum = res.result.scoretimes
        }
      })
    },
    //评分
    assessScore() {
      let params = {
        video_id: this.video_id,
        scoreid: this.value,
        content: this.assessinput
      }
      assessScore(params).then(res => {
        if (res.code === 0) {
          this.assessNum = res.result.scoretime
          this.$message({
            message: '评价成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '评价失败！',
            type: 'error'
          })
        }
      })
    },
    getVideolist(list, lastvid) {
      //let _this = this
      this.videolist = []
      let i = 0
      list.forEach(item => {
        if (item.children) {
          let nodelist = item.children
          nodelist.forEach(node => {
            if (node.children) {
              let leaflist = node.children
              leaflist.forEach(leaf => {
                if (lastvid === leaf.cc_id) {
                  this.idx = i
                }
                //_this.videolist.push(JSON.stringify(leaf))
                this.videolist.push(JSON.stringify(leaf))
                i++
              })
            } //else if (node.cc_id !== "undefined") {
            // _this.videolist.push(node);
            //}
          })
        } //else if (item.cc_id !== "undefined") {
        // _this.videolist.push(item);
        //}
      })
    },
    playVideoList() {
      if (this.idx >= this.videolist.length) this.idx = 0
      this.currvideo = JSON.parse(this.videolist[this.idx])
      let vid = this.currvideo.cc_id
      this.createPlayer(
        vid,
        this.currvideo.course_name,
        this.currvideo.course_id
      )
      this.idx++
    },
    //点击播放视频
    play_video(cc_id, is_finish, course_name, video_id, duration) {
      // if (is_finish === 1) {
      //   this.videolist.forEach((item, index) => {
      //     let video = JSON.parse(item)
      //     if (video.cc_id === cc_id) {
      //       this.idx = index
      //       this.currvideo = video
      //     }
      //   })
      //   this.createPlayer(cc_id, course_name)
      // }
      this.currvideo.video_id = video_id
      this.currvideo.duration = duration
      if (this.rule.order === 0) {
        for (let i = 0; i < this.video_data.length; i++) {
          for (let j = 0; j < this.video_data[i].children.length; j++) {
            for (
              let k = 0;
              k < this.video_data[i].children[j].children.length;
              k++
            ) {
              const data = this.video_data[i].children[j].children[k]
              if (data) {
                if (cc_id === data.cc_id) {
                  this.period = data.period
                  this.duration = data.duration
                  // this.total_duration = data.
                  data.is_finish = 3
                } else {
                  data.is_finish = this.old_video_data[i].children[j].children[
                    k
                  ].is_finish
                }
              }
            }
          }
        }
        this.video_id = video_id
        this.cc_id = cc_id
        this.playName = course_name
        //树状图默认选中
        this.$nextTick(() => {
          this.$refs['my-tree'].setCurrentKey(this.playName)
        })
        this.getAssessnum(video_id)
        this.createPlayer(this.cc_id, this.playName, this.video_id)
      } else {
        this.$message({
          message: '检测到规则为顺序播放，点击无效！',
          type: 'warning'
        })
      }
    },
    createPlayer(vid, course_name, video_id) {
      this.video = document.getElementById('test')
      this.player = window.createCCH5Player({
        vid: vid,
        siteid: 'B3900E03A0A4C537',
        playtype: 1,
        width: '100%',
        height: '100%',
        isShare: false,
        banDrag: false,
        open_barrage: false,
        progressbar_enable: this.progressbar_enable,
        parentNode: this.video,
        showRateBtn: false,
        // realAutoPlay: 'true'
        autoStart: 'true'
      })
      this.playName = course_name
      //树状图默认选中
      this.$nextTick(() => {
        this.$refs['my-tree'].setCurrentKey(this.playName)
      })
      this.getAssessnum(video_id)
    },
    nextPlayer() {
      this.player && this.player.destroy()
    },
    setPlayerCallBack() {
      window.onCCH5PlayerLoaded = this.onCCH5PlayerLoaded
      window.on_CCH5player_ready = this.onPlayerReady
      window.on_player_volumechange = this.onPlayerVolumeChange
      window.on_CCH5player_pause = this.onCCH5playerpause
      window.on_CCH5player_play = this.onCCH5playerplay
      window.on_CCH5player_ended = this.onCCH5playerended
    },
    onCCH5PlayerLoaded() {
      window.ccH5PlayerJsLoaded = true
    },
    onPlayerReady(obj) {
      //上次播放点
      //this.player.jumpToTime(this.historyPoint)
      //this.player.play()
      //window.cc_js_Player.setVolume(0.3)
    },
    onPlayerVolumeChange(volume, vid) {},
    onCCH5playerpause() {
      //暂停
      clearInterval(this.timer)
      clearInterval(this.time1)
      this.time1 = 0
      this.total_study_time = this.player.getPosition()
      if (Math.floor(this.total_study_time) == this.period) {
        this.is_finish = 1
      } else {
        this.is_finish = 2
      }
      this.ciRecoderEtime = Date.parse(new Date()) / 1000
      this.writeProgress(this.is_finish, this.total_study_time)
      this.duration = Date.parse(new Date()) - this.starttime
      this.starttime = 0
    },
    onCCH5playerplay(video, vid) {
      if (this.step == 'process' && this.type != 3) {
        this.player.pause()
        if (this.rule.playing == 1) {
          this.$confirm('检测到未识别，识别成功后将自动关闭?', '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.centerDialogVisible = true
            this.openCamera()
          })
        }
        if (this.rule.playing == 4) {
          this.$confirm('检测到未完成采集，采集成功后将自动关闭?', '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.dialogTableVisible = true
            this.openCameracollection()
          })
        }
        if (this.rule.playing == 5) {
          this.$confirm('检测到未完成活体检测，检测成功后将自动关闭?', '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.dialogBiopsy = true
            this.openBiopsy()
          })
        }
      } else {
        //this.type = 0;
        if (this.subject_id === this.subject_one) {
          this.subjectOnehours()
          if (this.subjectHour >= this.subject1_period) {
            if (this.rule.jump === 1) {
              getSubject({ licence_id: this.licence_id }).then(res => {
                for (let i = 0; i < res.result.subject_list.length; i++) {
                  if (
                    this.subject_id == res.result.subject_list[i].subject_id
                  ) {
                    this.$message({
                      message: '此科目学时已学完，自动跳转至下一科目',
                      type: 'warning'
                    })
                    this.$set(
                      this.subjectdata[i],
                      'classType',
                      this.subjectdata[i].classType === false
                    )
                    this.subjectdata[i + 1].classType = true
                    this.subject_id = res.result.subject_list[i + 1].subject_id
                    removeSubjectId()
                    setSubjectId(this.subject_id)
                    this.getCourses()
                    this.getProgress()
                    this.hoursLimit()
                    return false
                  }
                }
              })
            }
          }
        }
        if (this.is_pause === false) {
          this.player.pause()
          this.step = 'process'
          this.position = 'daily-process'
          this.point = this.player.getPosition()
          if (this.rule.playing === 1) {
            this.verify_type = 1
            //间隔弹出人脸识别
            this.$message({
              message: '请完成人脸识别',
              type: 'warning'
            })
            if (this.centerDialogVisible === false) {
              this.typeImage = 'process'
              this.showPopupConfirm()
            }
          } else if (this.rule.playing === 4) {
            this.verify_type = 4
            //间隔弹出人脸采集
            this.$message({
              message: '请完成人脸采集',
              type: 'warning'
            })
            if (this.dialogTableVisible === false) {
              this.typeImage = 'process'
              this.showcollection()
            }
          } else if (this.rule.playing === 5) {
            this.verify_type = 5 //暂时使用
            //间隔弹出活体检测
            this.$message({
              message: '请完成活体检测',
              type: 'warning'
            })
            if (this.dialogBiopsy === false) {
              this.typeImage = 'process'
              this.showopenBiopsy()
            }
          }
        }
        //判断今日学时是否上限
        if (
          this.rule.duration_limit > 0 &&
          this.hour_limit >= this.rule.duration_limit
        ) {
          this.player.pause()
          this.$message({
            message: '今日已达上限，明天继续观看',
            type: 'warning'
          })
          this.$router.push({ path: '/progress' })
          return
        }
        this.ciRecoderStime = Date.parse(new Date()) / 1000
        this.timer_duration = 0
        if (this.time1 == 0) {
          this.time1 = setInterval(() => {
            if (this.remain_duration >= 0) {
              this.remain_duration -= 0.25
            } else if (this.remain_duration < 0) {
              this.player.pause()
              this.$message({
                message: '今日已达上限，明天继续观看',
                type: 'warning'
              })
              this.$router.push({ path: '/progress' })
              return
            }
            this.timer_duration += 0.25 //每次提交电子日志观看时间
            this.logDuration += 0.25
            // this.getrule()
          }, 250)
        }
        // this.is_pay = JSON.parse(getResult()).is_pay
        // if (this.is_pay > 1) {
        // this.ciRecoderStime = Date.parse(new Date()) / 1000
        if (this.butname === '继续学习') {
          if (this.sTime <= this.nowTime && this.nowTime <= this.eTime) {
            if (this.isFrist) {
              //this.times++
              // alert(this.isFrist + ': ' + this.times)
              if (this.times === 1) {
                this.step = 'start'
                this.position = 'daily-start'
                this.isFrist = true
              } else {
                this.step = 'process'
                this.position = 'daily-process'
              }
            } else {
              this.step = 'start'
              this.position = 'daily-start'
              this.isFrist = true
            }
            // if (this.rule.length <= 0) {
            //   this.butname = '签退'
            //   this.verify_type = 0
            //   this.image = ''
            //   this.duration_limit = 0
            //   this.startstudy()
            // }
            //否
            if (this.rule.start === 0) {
              this.butname = '签退'
              this.verify_type = 0
              this.image = ''
              this.startstudy()
              //开始播放
              //this.onPlayerReady()
              //人脸识别
            } else if (this.rule.start === 1) {
              this.player.pause()
              this.verify_type = 1
              this.centerDialogVisible = true
              this.typeImage = 'start'
              this.openCamera()
              //人脸采集
            } else if (this.rule.start === 4) {
              this.player.pause()
              this.verify_type = 4
              this.dialogTableVisible = true
              this.typeImage = 'start'
              this.openCameracollection()
            } else if (this.rule.start === 5) {
              this.player.pause()
              this.verify_type = 5 //暂时使用
              //this.image = ''//暂时使用
              this.dialogBiopsy = true
              this.typeImage = 'start'
              this.openBiopsy()
            } else {
              this.butname = '签退'
              this.verify_type = 0
              this.image = ''
              this.startstudy()
            }
          } else {
            this.player.pause()
            this.$message({
              message: '当前时间段不能观看！',
              type: 'warning'
            })
          }
        }
        if (this.rule.playing === 1) {
          if (this.rule.photo_num === 1) {
            if (this.distinguishImageData.length <= 3) {
              this.timeMeter()
            }
          } else {
            this.timeMeter()
          }
        }
        if (this.rule.playing === 4) {
          if (this.rule.photo_num === 1) {
            if (this.collectionImageData.length <= 3) {
              this.timeMeter()
            }
          } else {
            this.timeMeter()
          }
        }
        if (this.rule.playing === 5) {
          if (this.rule.photo_num === 1) {
            if (this.biopsyImageData.length <= 3) {
              this.timeMeter()
            }
          } else {
            this.timeMeter()
          }
        }
        // if (this.rule.photo_num === 1) {
        //   if (
        //     this.rule.playing === 1 &&
        //     this.distinguishImageData.length <= 3
        //   ) {
        //     this.timeMeter()
        //   }
        //   if (this.rule.playing === 4 && this.collectionImageData.length <= 3) {
        //     this.timeMeter()
        //   }
        //   if (this.rule.playing === 5 && this.biopsyImageData.length <= 3) {
        //     this.timeMeter()
        //   }
        // } else if (this.rule.photo_num === 0) {
        //   this.timeMeter()
        // }
        for (let i = 0; i < this.video_data.length; i++) {
          for (let j = 0; j < this.video_data[i].children.length; j++) {
            for (
              let k = 0;
              k < this.video_data[i].children[j].children.length;
              k++
            ) {
              const data = this.video_data[i].children[j].children[k]
              if (data) {
                if (vid === data.cc_id) {
                  this.period = data.period
                  this.duration = data.duration
                  data.is_finish = 2
                } else {
                  data.is_finish = this.old_video_data[i].children[j].children[
                    k
                  ].is_finish
                }
              }
            }
          }
        }
        // //播放
        // document.getElementsByClassName('ccH5FullsBtn')[0].setAttribute('style', 'display:none')
        // document.getElementsByClassName('ccH5LoadBar')[0].setAttribute('style', 'display:none')
        // document.getElementsByClassName('ccH5sp')[0].setAttribute('style', 'display:none')
        // if (this.limitduration === 1) {
        //   this.$toast.fail('今日已达上限，明天继续观看')
        //   this.$router.push({ path: '/progress' })
        //   return
        // }
        //this.is_pay = JSON.parse(getResult()).is_pay
        // this.starttime = Date.parse(new Date())
        // } else {
        //   this.player.pause()
        //   // this.$message({
        //   //   message: '课程未付费',
        //   //   type: 'warning'
        //   // })
        // }
      }
    },
    onCCH5playerended(video, vid) {
      this.is_finish = 1
      this.ciRecoderEtime = Date.parse(new Date()) / 1000
      for (let i = 0; i < this.old_video_data.length; i++) {
        for (let j = 0; j < this.old_video_data[i].children.length; j++) {
          for (
            let k = 0;
            k < this.old_video_data[i].children[j].children.length;
            k++
          ) {
            const data = this.old_video_data[i].children[j].children[k]
            if (data) {
              if (vid === data.cc_id) {
                this.period = data.period
                this.duration = data.duration
                // this.total_duration = data.
                data.is_finish = 1
              }
            }
          }
        }
      }
      this.point = this.player.getPosition()
      if (this.subject_id === 1) {
        this.total_study_time = this.total_study_time + this.point
      }
      //结束
      this.writeProgress(1, this.point)
      if (this.rule.order === 1) {
        //下一个视频
        this.playVideoList()
      }
      if (this.rule.order === 0) {
        //下一个视频
        this.playVideoList()
      }
    },
    //计时器
    timeMeter() {
      //this.timerTime = 0
      // if (this.rule.check_interval > 0) {
      //   this.timer = setInterval(() => {
      //     this.player.pause()
      //     this.step = 'process'
      //     this.position = 'daily-process'
      //     this.point = this.player.getPosition()
      //     if (this.rule.playing === 1) {
      //       this.verify_type = 1
      //       //间隔弹出人脸识别
      //       if (this.centerDialogVisible === false) {
      //         this.typeImage = 'process'
      //         this.showPopupConfirm()
      //         clearInterval(this.timer)
      //       }
      //     } else if (this.rule.playing === 4) {
      //       this.verify_type = 4
      //       //间隔弹出人脸采集
      //       if (this.dialogTableVisible === false) {
      //         this.typeImage = 'process'
      //         this.showcollection()
      //       }
      //     }
      //     //this.timerTime++
      //   }, this.rule.check_interval * 1000)
      // }

      if (this.rule.check_interval > 0) {
        this.timer = setInterval(() => {
          if (this.timerTime >= this.rule.check_interval) {
            this.player.pause()
            this.step = 'process'
            this.position = 'daily-process'
            this.point = this.player.getPosition()
            if (this.rule.playing === 1) {
              this.verify_type = 1
              //间隔弹出人脸识别
              if (this.centerDialogVisible === false) {
                this.typeImage = 'process'
                this.showPopupConfirm()
              }
            } else if (this.rule.playing === 4) {
              this.verify_type = 4
              //间隔弹出人脸采集
              if (this.dialogTableVisible === false) {
                this.typeImage = 'process'
                this.showcollection()
              }
            } else if (this.rule.playing === 5) {
              this.verify_type = 5 //暂时使用
              //间隔弹出活体检测
              if (this.dialogBiopsy === false) {
                this.typeImage = 'process'
                this.showopenBiopsy()
              }
            }
          }
          this.timerTime += 0.25
        }, 250)
      }
    },
    //提交课程学习记录
    writeProgress(is_finish, point) {
      let timerTime = 0
      if (this.timer_duration < 1) {
        // this.$message({
        //   message: "观看不足1秒，请继续观看",
        //   type: "warning"
        // })
        return
      } else {
        timerTime = this.timer_duration
      }
      this.duration = this.duration + Date.parse(new Date()) - this.starttime
      let params = {
        uuid: getUuid(),
        term: this.term,
        video_id: this.currvideo.video_id,
        subject_id: this.subject_id,
        point: point,
        period: this.period,
        duration: this.currvideo.duration,
        is_finish: is_finish,
        s_time: this.ciRecoderStime,
        e_time: this.ciRecoderEtime,
        total_duration: this.total_duration,
        cata_id: this.cata_id,
        timer_duration: this.timer_duration,
        is_exam_duration: 0
      }
      studydaily(params).then(res => {
        if (res.code === 0) {
          this.duration = 0 //清空计时器
          this.starttime = 0
        } else if (res.code === 10) {
          this.limitduration = 1
          this.$toast.fail(res.msg)
          this.player.destroy()
          this.$router.push({ path: '/studytime' })
        }
      })
    },
    //继续学习
    keepOn(butname) {
      if (butname === '继续学习') {
        if (this.sTime <= this.nowTime && this.nowTime <= this.eTime) {
          if (this.isFrist) {
            //this.times++
            // alert(this.isFrist + ': ' + this.times)
            if (this.times === 1) {
              this.step = 'start'
              this.position = 'daily-start'
              this.isFrist = true
            } else {
              this.step = 'process'
              this.position = 'daily-process'
            }
          } else {
            this.step = 'start'
            this.position = 'daily-start'
            this.isFrist = true
          }
          //否
          if (this.rule.start === 0) {
            this.butname = '签退'
            this.verify_type = 0
            this.image = ''
            this.startstudy()
            //人脸识别
          } else if (this.rule.start === 1) {
            this.player.pause()
            this.verify_type = 1
            this.centerDialogVisible = true
            this.typeImage = 'start'
            this.openCamera()
            //人脸采集
          } else if (this.rule.start === 4) {
            this.player.pause()
            this.verify_type = 4
            this.dialogTableVisible = true
            this.typeImage = 'start'
            // this.cameracollection = true
            // this.imgscollection = false
            this.openCameracollection()
          } else if (this.rule.start === 5) {
            this.player.pause()
            this.verify_type = 5 //暂时使用
            //this.image = ''//暂时使用
            this.dialogBiopsy = true
            this.typeImage = 'start'
            this.openBiopsy()
          } else {
            this.butname = '签退'
            this.verify_type = 0
            this.image = ''
            this.startstudy()
          }
        } else {
          this.$message({
            message: '当前时间段不能观看！',
            type: 'warning'
          })
        }
      } else if (butname === '签退') {
        if (
          this.rule.log_duration_limit > 0 &&
          this.rule.log_duration_limit > this.logDuration
        ) {
          this.$message({
            message:
              '未满' + this.rule.log_duration_limit / 60 + '分钟，不能签退',
            type: 'warning'
          })
          return false
        }
        this.step = 'end'
        this.position = 'daily-end'
        //否
        if (this.rule.logout === 0) {
          this.verify_type = 0
          this.image = ''
          this.player.pause()
          this.point = this.player.getPosition()
          //this.writeProgress(this.is_finish, this.point)
          this.startstudy()
          this.daily_duration = 0
          this.progress = 0
          this.getProgress()
          //人脸识别
        } else if (this.rule.logout === 1) {
          this.verify_type = 1
          this.centerDialogVisible = true
          this.typeImage = 'logout'
          this.openCamera()
          //人脸采集
        } else if (this.rule.logout === 4) {
          this.verify_type = 4
          this.dialogTableVisible = true
          this.typeImage = 'logout'
          this.openCameracollection()
        } else if (this.rule.logout === 5) {
          this.verify_type = 5 //暂时使用
          this.dialogBiopsy = true
          this.typeImage = 'logout'
          this.openBiopsy()
        } else {
          this.verify_type = 0
          this.image = ''
          this.player.pause()
          this.point = this.player.getPosition()
          //this.writeProgress(this.is_finish, this.point)
          this.startstudy()
          this.daily_duration = 0
          this.progress = 0
          this.getProgress()
        }
      }
    },
    //开启采集摄像头
    openCameracollection() {
      this.player.pause()
      this.cameracollection = true
      this.imgscollection = false
      let mediaStreamTrack = null // 视频对象(全局)
      let constraints = {
        video: { width: 168, height: 235 },
        audio: false
      }
      //获得video摄像头
      let promise = navigator.mediaDevices.getUserMedia(constraints)
      promise.then(mediaStream => {
        mediaStreamTrack = mediaStream.getVideoTracks()
        this.$refs['videocollection'].srcObject = mediaStream
        this.$refs['videocollection'].play()
      })
    },
    //开启识别摄像头
    openCamera() {
      this.player.pause()
      this.camera = true
      this.imgs = false
      let mediaStreamTrack = null // 视频对象(全局)
      let constraints = {
        video: { width: 350, height: 350 },
        audio: false
      }
      //获得video摄像头
      let promise = navigator.mediaDevices.getUserMedia(constraints)
      promise.then(mediaStream => {
        mediaStreamTrack = mediaStream.getVideoTracks()
        this.$refs['video'].srcObject = mediaStream
        this.$refs['video'].play()
      })
    },
    //关闭识别摄像头
    closeVideo() {
      this.centerDialogVisible = false
      if (document.getElementById('video').srcObject != null) {
        let stream = document.getElementById('video').srcObject
        let tracks = stream.getTracks()
        tracks.forEach(function(track) {
          track.stop()
        })
        document.getElementById('video').srcObject = null
      }

      // if (this.typeImage === 'process') {
      //   this.is_pause = false
      // }
    },
    //关闭采集摄像头
    closeVideocollection() {
      this.dialogTableVisible = false
      if (document.getElementById('videocollection').srcObject != null) {
        let stream = document.getElementById('videocollection').srcObject
        let tracks = stream.getTracks()
        tracks.forEach(function(track) {
          track.stop()
        })
        document.getElementById('videocollection').srcObject = null
      }
    },
    //开始识别
    startdis() {
      this.camera = false
      this.imgs = true
      //获得Canvas对象
      let video = document.getElementById('video')
      let canvas = document.getElementById('canvas')
      let ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, 350, 350)
      // toDataURL  ---  可传入'image/png'---默认, 'image/jpeg'
      let img = document.getElementById('canvas').toDataURL()
      this.image = img
      // 这里的img就是得到的图片
      document.getElementById('imgTag').src = img
      this.startstudy()
    },
    //采集照片
    takephotos() {
      this.cameracollection = false
      this.imgscollection = true
      //获得Canvas对象
      let video = document.getElementById('videocollection')
      let canvas = document.getElementById('canvascollection')
      let ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, 168, 235)
      // toDataURL  ---  可传入'image/png'---默认, 'image/jpeg'
      let img = document.getElementById('canvascollection').toDataURL()
      this.image = img
      // 这里的img就是得到的图片
      document.getElementById('imgTagcollection').src = img
      if (JSON.parse(getResult()).is_collection === 0) {
        //this.writeProgress(this.is_finish, this.point)
        this.startstudy()
      } else if (JSON.parse(getResult()).is_collection === 1) {
        collection({ image: this.image }).then(res => {
          if (res.code === 0) {
            //this.writeProgress(this.is_finish, this.point)
            this.startstudy()
          } else {
            this.$message({
              message: '采集失败！',
              type: 'success'
            })
          }
        })
      }
    },
    //活体摄像头
    openBiopsy() {
      this.player.pause()
      this.videoBiopsy = true
      this.imgsBiopsy = false
      this.isStart = true
      this.isMiddle = false
      this.isEnd = false
      this.isEnds = false
      this.progressPercent = 0
      var items = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
      this.strBiopsy = this.getRandomArrayElements(items, 4).toString()
      let mediaStreamTrack = null // 视频对象(全局)
      let constraints = {
        video: { width: 168, height: 235 },
        // 录制声音
        audio: true
      }
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(mediaStream => {
          // var video = document.querySelector('myCamera')
          this.video = this.$refs.myCamera
          this.video.srcObject = mediaStream
          this.recorder = RecordRTC(mediaStream)
          this.video.onloadedmetadata = e => {
            this.video.play()
          }
        })
        .catch(function(err) {
          if (err.message == 'Permission denied by system') {
            this.$message({
              message: '请开启电脑的麦克风和摄像头权限',
              type: 'warning'
            })
          }
        })
    },
    //关闭活体检测弹窗
    closeBiopsy() {
      this.dialogBiopsy = false
      if (document.getElementById('myCamera').srcObject != null) {
        let stream = document.getElementById('myCamera').srcObject
        let tracks = stream.getTracks()
        tracks.forEach(function(track) {
          track.stop()
        })
        document.getElementById('myCamera').srcObject = null
      }
    },
    //随机数
    getRandomArrayElements(arr, count) {
      var shuffled = arr.slice(0),
        i = arr.length,
        min = i - count,
        temp,
        index
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random())
        temp = shuffled[index]
        shuffled[index] = shuffled[i]
        shuffled[i] = temp
      }
      return shuffled.slice(min)
    },
    // 开始录制
    starting() {
      this.$confirm('请用普通话朗读上方四个数字', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isStart = false
          this.isEnd = false
          this.isEnds = false
          this.isMiddle = true
          this.recorder.startRecording()
          this.timeBiopsy = setInterval(() => {
            this.progressPercent += 1
            if (this.progressPercent > 100) {
              this.progressPercent = 100
            }
            if (this.progressPercent == 100) {
              clearInterval(this.timeBiopsy)
              this.isStart = false
              this.isMiddle = false
              //this.isEnd = true
              this.recorder.stopRecording(() => {
                const downloadLink = document.createElement('a')
                downloadLink.href = URL.createObjectURL(this.recorder.getBlob())
                // 下面是定义文件名字
                downloadLink.download = `${new Date().getTime()}.mp4`
                // 点击a标签
                //downloadLink.click()

                this.videoBiopsy = false
                this.imgsBiopsy = true
                //获得Canvas对象
                let video = document.getElementById('myCamera')
                let canvas = document.getElementById('canvasBiopsy')
                let ctx = canvas.getContext('2d')
                ctx.drawImage(video, 0, 0, 168, 235)
                // toDataURL  ---  可传入'image/png'---默认, 'image/jpeg'
                let img = document.getElementById('canvasBiopsy').toDataURL()
                this.image = img
                document.getElementById('imgTagBiopsy').src = img
                this.startstudy()
              })
            }
          }, 20)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    stopping() {
      // this.videoBiopsy = false
      // this.imgsBiopsy = true
      // //获得Canvas对象
      // let video = document.getElementById('myCamera')
      // let canvas = document.getElementById('canvasBiopsy')
      // let ctx = canvas.getContext('2d')
      // ctx.drawImage(video, 0, 0, 168, 235)
      // // toDataURL  ---  可传入'image/png'---默认, 'image/jpeg'
      // let img = document.getElementById('canvasBiopsy').toDataURL()
      // this.image = img
      // console.log(this.image)
      // document.getElementById('imgTagBiopsy').src = img
      this.closeBiopsy()
      //this.startstudy()
    },

    //开始学习
    startstudy() {
      this.uuid = getUuid()
      if (this.uuid === undefined) {
        this.uuid = ''
      }
      if (this.step === 'start') {
        this.uuid = ''
      }
      let params = {
        step: this.step,
        verify_type: this.verify_type,
        position: this.position,
        subject_id: parseInt(this.subject_id),
        image: this.image,
        duration_limit: this.duration_limit,
        term: parseInt(this.term),
        uuid: this.uuid,
        course_id: this.video_id,
        is_exam_duration: 0
      }
      gethandler(params).then(res => {
        if (this.step === 'start') {
          if (this.verify_type === 0) {
            if (res.code === 0) {
              // this.remain_duration = res.result.remain_duration
              this.remain_duration = res.result.remain_duration
              setUuid(res.result.uuid)
              this.butname = '签退'
              // this.player.play()
            }
          } else if (this.verify_type === 1) {
            if (res.code === 0) {
              this.times++
              this.remain_duration = res.result.remain_duration
              setUuid(res.result.uuid)
              this.innerVisible = true
              this.playstatus = 'icon-checkmark1'
              this.playtext = '识别通过！'
              this.type = 1
            } else {
              this.innerVisible = true
              this.playstatus = 'icon-cancel1'
              this.playtext = '识别失败！'
              this.type = 0
            }
          } else if (this.verify_type === 4) {
            if (res.code === 0) {
              this.times++
              this.remain_duration = res.result.remain_duration
              setUuid(res.result.uuid)
              this.dialogTableVisible = false
              this.$message({
                message: '采集成功！',
                type: 'success'
              })
              this.closeVideocollection()
              this.butname = '签退'
              this.player.play()
            } else {
              this.dialogTableVisible = false
              this.$message({
                message: '采集失败！',
                type: 'error'
              })
            }
          } else if (this.verify_type === 5) {
            if (res.code === 0) {
              this.times++
              this.remain_duration = res.result.remain_duration
              setUuid(res.result.uuid)
              this.dialogBiopsy = false
              this.$message({
                message: '检测成功！',
                type: 'success'
              })
              this.closeBiopsy()
              this.butname = '签退'
              this.player.play()
            } else {
              this.isEnd = false
              this.isEnds = true
              this.$message({
                message: '检测失败！',
                type: 'error'
              })
            }
          }
        } else if (this.step === 'end') {
          if (this.verify_type === 0) {
            if (res.code === 0) {
              this.butname = '继续学习'
              clearInterval(this.time1)
              this.logDuration = 0
              this.player.pause()
              this.daily_duration = 0
              this.progress = 0
              this.getProgress()
              this.$router.push({ path: '/progress/mycourses' })
            }
          } else if (this.verify_type === 1) {
            if (res.code === 0) {
              //setUuid(res.result.uuid)
              this.innerVisible = true
              this.playstatus = 'icon-checkmark1'
              this.playtext = '识别通过！'
              this.type = 2
            } else {
              this.innerVisible = true
              this.playstatus = 'icon-cancel1'
              this.playtext = '识别失败！'
              this.type = 0
            }
          } else if (this.verify_type === 4) {
            if (res.code === 0) {
              //setUuid(res.result.uuid)
              this.dialogTableVisible = false
              this.$message({
                message: '采集成功！',
                type: 'success'
              })
              this.closeVideocollection()
              this.butname = '继续学习'
              clearInterval(this.time1)
              this.logDuration = 0
              this.player.pause()
              this.daily_duration = 0
              this.progress = 0
              this.getProgress()
              this.$router.push({ path: '/progress/mycourses' })
            } else {
              this.dialogTableVisible = false
              this.$message({
                message: '采集失败！',
                type: 'error'
              })
            }
          } else if (this.verify_type === 5) {
            if (res.code === 0) {
              //setUuid(res.result.uuid)
              this.dialogBiopsy = false
              this.$message({
                message: '检测成功！',
                type: 'success'
              })
              this.closeBiopsy()
              this.butname = '继续学习'
              clearInterval(this.time1)
              this.logDuration = 0
              this.player.pause()
              this.daily_duration = 0
              this.progress = 0
              this.getProgress()
              this.$router.push({ path: '/progress/mycourses' })
            } else {
              this.isEnd = false
              this.isEnds = true
              this.$message({
                message: '检测失败！',
                type: 'error'
              })
            }
          }
        } else if (this.step === 'process') {
          if (this.verify_type === 0) {
            if (res.code === 0) {
              this.butname = '签退'
              this.player.play()
            }
          } else if (this.verify_type === 1) {
            if (res.code === 0) {
              this.times++
              //setUuid(res.result.uuid)
              this.distinguishImageData.push(this.image)
              this.innerVisible = true
              this.playstatus = 'icon-checkmark1'
              this.playtext = '识别通过！'
              this.type = 3
              this.is_pause = true
              // clearInterval(this.timer)
              // this.timer = null
            } else {
              this.innerVisible = true
              this.playstatus = 'icon-cancel1'
              this.playtext = '识别失败！'
              this.type = 0
              this.is_pause = false
            }
          } else if (this.verify_type === 4) {
            if (res.code === 0) {
              this.times++
              //setUuid(res.result.uuid)
              this.dialogTableVisible = false
              this.collectionImageData.push(this.image)
              this.$message({
                message: '采集成功！',
                type: 'success'
              })
              this.is_pause = true
              this.type = 3
              clearInterval(this.timer)
              this.timerTime = 0
              this.timer = null
              this.closeVideocollection()
              this.butname = '签退'
              this.player.play()
            } else {
              this.dialogTableVisible = false
              this.$message({
                message: '采集失败！',
                type: 'error'
              })
              this.is_pause = false
            }
          } else if (this.verify_type === 5) {
            if (res.code === 0) {
              this.times++
              //setUuid(res.result.uuid)
              this.dialogBiopsy = false
              this.biopsyImageData.push(this.image)
              this.$message({
                message: '检测成功！',
                type: 'success'
              })
              this.is_pause = true
              this.type = 3
              clearInterval(this.timer)
              this.timerTime = 0
              this.timer = null
              this.closeBiopsy()
              this.butname = '签退'
              this.player.play()
            } else {
              this.isEnd = false
              this.isEnds = true
              this.$message({
                message: '检测失败！',
                type: 'error'
              })
              this.is_pause = false
            }
          }
        }
      })
    },
    //识别结束确定
    playresult(type) {
      // type
      // 3:过程中识别通过
      // 2:结束  识别通过
      // 1:开始  识别通过
      // 0:识别失败
      if (type === 3) {
        this.innerVisible = false
        this.centerDialogVisible = false
        this.closeVideo()
        this.butname = '签退'
        clearInterval(this.timer)
        this.timerTime = 0
        this.timer = null
        this.player.play()
      } else if (type === 2) {
        this.innerVisible = false
        this.centerDialogVisible = false
        this.closeVideo()
        this.butname = '继续学习'
        clearInterval(this.time1)
        this.logDuration = 0
        this.player.pause()
        this.daily_duration = 0
        this.progress = 0
        this.getProgress()
        this.$router.push({ path: '/progress/mycourses' })
      } else if (type === 1) {
        this.innerVisible = false
        this.centerDialogVisible = false
        this.closeVideo()
        this.butname = '签退'
        this.player.play()
      } else if (type === 0) {
        this.innerVisible = false
        this.centerDialogVisible = true
        this.camera = true
        this.imgs = false
        this.openCamera()
      }
    },
    showPopupConfirm() {
      this.openCamera()
      this.curTime = Date.parse(new Date())
      this.centerDialogVisible = true
    },
    showcollection() {
      this.openCameracollection()
      this.curTime = Date.parse(new Date())
      this.dialogTableVisible = true
    },
    //活体弹窗和摄像头
    showopenBiopsy() {
      this.openBiopsy()
      this.curTime = Date.parse(new Date())
      this.dialogBiopsy = true
    },
    setpause() {
      if (document.hidden) {
        document.title = '用户离开了'
        this.player.pause()
        clearInterval(this.timer)
        this.timer = null
      } else {
        document.title = '用户回来了'
      }
    }
  },
  // destroyed() {
  //   this.player && this.player.destroy()
  //   clearInterval(this.timer)
  //   this.timer = null
  // },
  mounted() {
    document.addEventListener('visibilitychange', this.setpause)
    window.vue = this
  }
}
window.onblur = function() {
  if (window.vue.$route.path == '/catalogs') {
    window.vue.player.pause()
    clearInterval(window.vue.timer)
    window.vue.timer = null
  }
}
window.onfocus = function() {
  if (window.vue.$route.path == '/catalogs') {
    window.vue.player.play()
  }
  // window.vue.player.play()
}
</script>
<style scoped>
#test {
  width: 100%;
  height: 500px;
  max-width: 965px;
}

.btn {
  width: 100px;
  height: 36px;
  line-height: 40px;
  text-align: center;
  background: #ff6b3a;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 70px;
  cursor: pointer;
  margin-left: 20px;
}

.desc {
  padding: 0 20px;
}

.desc p {
  font-size: 14px;
  line-height: 30px;
}

.desc p > span {
  color: #ff6b3a;
}

.title {
  margin-right: 10px;
}

.tip {
  text-indent: 2em;
}
.custom-tree-node {
  width: 100%;
}
</style>
